exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-playground-jsx": () => import("./../../../src/pages/playground.jsx" /* webpackChunkName: "component---src-pages-playground-jsx" */),
  "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-docs-api-mdx": () => import("./../../../src/templates/doc.jsx?__contentFilePath=/opt/render/project/src/packages/website/content/docs/api.mdx" /* webpackChunkName: "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-docs-api-mdx" */),
  "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-docs-configuration-mdx": () => import("./../../../src/templates/doc.jsx?__contentFilePath=/opt/render/project/src/packages/website/content/docs/configuration.mdx" /* webpackChunkName: "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-docs-configuration-mdx" */),
  "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-docs-delta-mdx": () => import("./../../../src/templates/doc.jsx?__contentFilePath=/opt/render/project/src/packages/website/content/docs/delta.mdx" /* webpackChunkName: "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-docs-delta-mdx" */),
  "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-docs-download-mdx": () => import("./../../../src/templates/doc.jsx?__contentFilePath=/opt/render/project/src/packages/website/content/docs/download.mdx" /* webpackChunkName: "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-docs-download-mdx" */),
  "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-docs-formats-mdx": () => import("./../../../src/templates/doc.jsx?__contentFilePath=/opt/render/project/src/packages/website/content/docs/formats.mdx" /* webpackChunkName: "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-docs-formats-mdx" */),
  "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-docs-modules-clipboard-mdx": () => import("./../../../src/templates/doc.jsx?__contentFilePath=/opt/render/project/src/packages/website/content/docs/modules/clipboard.mdx" /* webpackChunkName: "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-docs-modules-clipboard-mdx" */),
  "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-docs-modules-history-mdx": () => import("./../../../src/templates/doc.jsx?__contentFilePath=/opt/render/project/src/packages/website/content/docs/modules/history.mdx" /* webpackChunkName: "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-docs-modules-history-mdx" */),
  "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-docs-modules-keyboard-mdx": () => import("./../../../src/templates/doc.jsx?__contentFilePath=/opt/render/project/src/packages/website/content/docs/modules/keyboard.mdx" /* webpackChunkName: "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-docs-modules-keyboard-mdx" */),
  "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-docs-modules-mdx": () => import("./../../../src/templates/doc.jsx?__contentFilePath=/opt/render/project/src/packages/website/content/docs/modules.mdx" /* webpackChunkName: "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-docs-modules-mdx" */),
  "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-docs-modules-syntax-mdx": () => import("./../../../src/templates/doc.jsx?__contentFilePath=/opt/render/project/src/packages/website/content/docs/modules/syntax.mdx" /* webpackChunkName: "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-docs-modules-syntax-mdx" */),
  "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-docs-modules-toolbar-mdx": () => import("./../../../src/templates/doc.jsx?__contentFilePath=/opt/render/project/src/packages/website/content/docs/modules/toolbar.mdx" /* webpackChunkName: "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-docs-modules-toolbar-mdx" */),
  "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-docs-quickstart-mdx": () => import("./../../../src/templates/doc.jsx?__contentFilePath=/opt/render/project/src/packages/website/content/docs/quickstart.mdx" /* webpackChunkName: "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-docs-quickstart-mdx" */),
  "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-docs-themes-mdx": () => import("./../../../src/templates/doc.jsx?__contentFilePath=/opt/render/project/src/packages/website/content/docs/themes.mdx" /* webpackChunkName: "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-docs-themes-mdx" */),
  "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-guides-adding-quill-to-your-build-pipeline-mdx": () => import("./../../../src/templates/doc.jsx?__contentFilePath=/opt/render/project/src/packages/website/content/guides/adding-quill-to-your-build-pipeline.mdx" /* webpackChunkName: "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-guides-adding-quill-to-your-build-pipeline-mdx" */),
  "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-guides-building-a-custom-module-mdx": () => import("./../../../src/templates/doc.jsx?__contentFilePath=/opt/render/project/src/packages/website/content/guides/building-a-custom-module.mdx" /* webpackChunkName: "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-guides-building-a-custom-module-mdx" */),
  "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-guides-cloning-medium-with-parchment-mdx": () => import("./../../../src/templates/doc.jsx?__contentFilePath=/opt/render/project/src/packages/website/content/guides/cloning-medium-with-parchment.mdx" /* webpackChunkName: "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-guides-cloning-medium-with-parchment-mdx" */),
  "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-guides-comparison-with-other-rich-text-editors-mdx": () => import("./../../../src/templates/doc.jsx?__contentFilePath=/opt/render/project/src/packages/website/content/guides/comparison-with-other-rich-text-editors.mdx" /* webpackChunkName: "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-guides-comparison-with-other-rich-text-editors-mdx" */),
  "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-guides-designing-the-delta-format-mdx": () => import("./../../../src/templates/doc.jsx?__contentFilePath=/opt/render/project/src/packages/website/content/guides/designing-the-delta-format.mdx" /* webpackChunkName: "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-guides-designing-the-delta-format-mdx" */),
  "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-guides-how-to-customize-quill-mdx": () => import("./../../../src/templates/doc.jsx?__contentFilePath=/opt/render/project/src/packages/website/content/guides/how-to-customize-quill.mdx" /* webpackChunkName: "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-guides-how-to-customize-quill-mdx" */),
  "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-guides-upgrading-to-1-0-mdx": () => import("./../../../src/templates/doc.jsx?__contentFilePath=/opt/render/project/src/packages/website/content/guides/upgrading-to-1-0.mdx" /* webpackChunkName: "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-guides-upgrading-to-1-0-mdx" */),
  "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-guides-upgrading-to-2-0-mdx": () => import("./../../../src/templates/doc.jsx?__contentFilePath=/opt/render/project/src/packages/website/content/guides/upgrading-to-2-0.mdx" /* webpackChunkName: "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-guides-upgrading-to-2-0-mdx" */),
  "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-guides-why-quill-mdx": () => import("./../../../src/templates/doc.jsx?__contentFilePath=/opt/render/project/src/packages/website/content/guides/why-quill.mdx" /* webpackChunkName: "component---src-templates-doc-jsx-content-file-path-opt-render-project-src-packages-website-content-guides-why-quill-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-render-project-src-packages-website-content-blog-a-new-delta-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/render/project/src/packages/website/content/blog/a-new-delta.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-render-project-src-packages-website-content-blog-a-new-delta-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-render-project-src-packages-website-content-blog-an-official-cdn-for-quill-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/render/project/src/packages/website/content/blog/an-official-cdn-for-quill.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-render-project-src-packages-website-content-blog-an-official-cdn-for-quill-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-render-project-src-packages-website-content-blog-announcing-quill-1-0-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/render/project/src/packages/website/content/blog/announcing-quill-1-0.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-render-project-src-packages-website-content-blog-announcing-quill-1-0-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-render-project-src-packages-website-content-blog-are-we-there-yet-to-1-0-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/render/project/src/packages/website/content/blog/are-we-there-yet-to-1-0.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-render-project-src-packages-website-content-blog-are-we-there-yet-to-1-0-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-render-project-src-packages-website-content-blog-quill-1-0-beta-release-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/render/project/src/packages/website/content/blog/quill-1-0-beta-release.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-render-project-src-packages-website-content-blog-quill-1-0-beta-release-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-render-project-src-packages-website-content-blog-quill-1-0-release-candidate-released-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/render/project/src/packages/website/content/blog/quill-1-0-release-candidate-released.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-render-project-src-packages-website-content-blog-quill-1-0-release-candidate-released-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-render-project-src-packages-website-content-blog-quill-v-0-19-no-more-iframes-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/render/project/src/packages/website/content/blog/quill-v0-19-no-more-iframes.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-render-project-src-packages-website-content-blog-quill-v-0-19-no-more-iframes-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-render-project-src-packages-website-content-blog-the-road-to-1-0-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/render/project/src/packages/website/content/blog/the-road-to-1-0.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-render-project-src-packages-website-content-blog-the-road-to-1-0-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-render-project-src-packages-website-content-blog-upgrading-to-rich-text-deltas-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/render/project/src/packages/website/content/blog/upgrading-to-rich-text-deltas.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-render-project-src-packages-website-content-blog-upgrading-to-rich-text-deltas-mdx" */),
  "component---src-templates-standalone-jsx-content-file-path-opt-render-project-src-packages-website-content-standalone-autogrow-mdx": () => import("./../../../src/templates/standalone.jsx?__contentFilePath=/opt/render/project/src/packages/website/content/standalone/autogrow.mdx" /* webpackChunkName: "component---src-templates-standalone-jsx-content-file-path-opt-render-project-src-packages-website-content-standalone-autogrow-mdx" */),
  "component---src-templates-standalone-jsx-content-file-path-opt-render-project-src-packages-website-content-standalone-basic-mdx": () => import("./../../../src/templates/standalone.jsx?__contentFilePath=/opt/render/project/src/packages/website/content/standalone/basic.mdx" /* webpackChunkName: "component---src-templates-standalone-jsx-content-file-path-opt-render-project-src-packages-website-content-standalone-basic-mdx" */),
  "component---src-templates-standalone-jsx-content-file-path-opt-render-project-src-packages-website-content-standalone-bubble-mdx": () => import("./../../../src/templates/standalone.jsx?__contentFilePath=/opt/render/project/src/packages/website/content/standalone/bubble.mdx" /* webpackChunkName: "component---src-templates-standalone-jsx-content-file-path-opt-render-project-src-packages-website-content-standalone-bubble-mdx" */),
  "component---src-templates-standalone-jsx-content-file-path-opt-render-project-src-packages-website-content-standalone-full-mdx": () => import("./../../../src/templates/standalone.jsx?__contentFilePath=/opt/render/project/src/packages/website/content/standalone/full.mdx" /* webpackChunkName: "component---src-templates-standalone-jsx-content-file-path-opt-render-project-src-packages-website-content-standalone-full-mdx" */),
  "component---src-templates-standalone-jsx-content-file-path-opt-render-project-src-packages-website-content-standalone-snow-mdx": () => import("./../../../src/templates/standalone.jsx?__contentFilePath=/opt/render/project/src/packages/website/content/standalone/snow.mdx" /* webpackChunkName: "component---src-templates-standalone-jsx-content-file-path-opt-render-project-src-packages-website-content-standalone-snow-mdx" */),
  "component---src-templates-standalone-jsx-content-file-path-opt-render-project-src-packages-website-content-standalone-stress-mdx": () => import("./../../../src/templates/standalone.jsx?__contentFilePath=/opt/render/project/src/packages/website/content/standalone/stress.mdx" /* webpackChunkName: "component---src-templates-standalone-jsx-content-file-path-opt-render-project-src-packages-website-content-standalone-stress-mdx" */)
}

